<nav class="navbar navbar-expand-lg navbar-light nav-panel">
  <div class="container-fluid">
    <a href="tel:+48694181413" class="phone-number fw-bold user-select-none text-decoration-none">+48 694 181 413</a>
    <a class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false">
      <span class="navbar-toggler-ion">≡</span>
    </a>
    <div #navlist class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item">
          <a (click)="hideNav()" class="nav-link active" aria-current="page" routerLink="/">Strona główna</a>
        </li>
        <li class="nav-item">
          <a (click)="hideNav()" class="nav-link" routerLink="/contact">Kontakt</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

@if (this.app.hero) {
<div class="hero">
  <div id="logodiv">
      <img class="logo-slide d-none d-md-block " src="../assets/logo/logo.png" alt="logo pralnia primo">
      <img class="logo-slide-mobile d-md-none" src="../assets/logo/logo_primo.png" alt="logo pralnia primo">
    </div>
  <div id="slide_show">
    @if (this.app.heroVideo) {
      <video playsinline="true" autoplay="true" muted="true" loop="true" poster="" class="hero-video">
          <source [src]="app.heroVideo" type="video/mp4">
      </video>
    }
    @else {
      <img class="hero-image" [src]="app.hero" alt="logo_pralniaprimo">
    }
      <div class="overlay"></div>
  </div>
</div>
}