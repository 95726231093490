import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private app: AppComponent) { }

  postAnimation: number
  scrolling: boolean
  ngOnInit(): void {
    this.app.hero = '../assets/photos/baner-home.jpg'
    this.app.heroVideo = '../assets/videos/pranie-hero.mp4'
    this.postAnimation = 0
    this.scrolling = false
  }

  ngAfterViewInit(): void {
    let tmp = this
    $(window).on('scroll', function () {
      tmp.scrolling = true;
    });

    setInterval(function () {
      if (tmp.scrolling) {
        tmp.scrolling = false;
        let post1 = $("#post1").offset().top;
        let post2 = $("#post2").offset().top;
        let post3 = $("#post3").offset().top;
        var scroll = $(window).scrollTop();
        if (scroll >= post1 - window.innerHeight && tmp.postAnimation < 1) {
          $("#post1").css("left", "0");
          tmp.postAnimation++
        }
        if (scroll >= post2 - window.innerHeight && tmp.postAnimation < 2) {
          $("#post2").css("right", "0");
          tmp.postAnimation++
        }
        if (scroll >= post3 - window.innerHeight && tmp.postAnimation < 3) {
          $("#post3").css("left", "0");
          tmp.postAnimation++
        }
      }
    }, 300);
}

ngOnDestroy() {
  this.app.hero = ''
  this.app.heroVideo = ''
  this.postAnimation = 0
}

}
