<main>
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-5 col-lg-4 py-4">
                <h3>Kontakt</h3>
                <div class="contact_info">
                    <p><b>Telefon:</b> +48 694 181 413</p>
                    <p><b>Adres:</b> Nowęcin ul.Żwirowa 1</p>
                    <p><b>Poczta:</b> 84-360 Łeba</p>
                </div>
            </div>
            <div class="col-md-5 col-lg-4 py-4">
                <h3>Godziny otwarcia</h3>
                <div class="contact_info">
                    <p><b>8.00 - 16.00</b> od poniedziałku do soboty</p>
                </div>
            </div>
        </div>
    </div>

</main>