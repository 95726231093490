<main>
    <div class="container mt-3">
        <div class="row text-justify my-lg-5">
            <div class="d-flex flex-column mx-auto col-lg-8 p-2">
                <h2 class="text-center">O nas</h2>
                <p>Zapewniamy kompleksową obsługę pralniczą dla różnorodnych branż, spełniając potrzeby właścicieli hoteli, moteli, pensjonatów, restauracji, gabinetów lekarskich i innych miejsc, gdzie istnieje konieczność prania bielizny stołowej, pościeli czy odzieży służbowej. Nasze usługi są szybkie, skuteczne i dostosowane do specyfiki Twojego biznesu.</p>
            </div>
        </div>
    </div>
    <div class="container carousel">
        <div class="row justify-content-center">
            <div class="carousel-items col-6 col-md-3">
                <img src="../assets/photos/wiszace-pranie.jpg" alt="pranie">
                <h4>Pranie</h4>
            </div>
            <div class="carousel-items col-6 col-md-3">
                <img src="../assets/photos/maglowanie.jpg" alt="maglowanie">
                <h4>Maglowanie</h4>
            </div>
            <div class="carousel-items col-6 col-md-3">
                <img src="../assets/photos/prasowanie.jpg" alt="prasowanie">
                <h4>Prasowanie</h4>
            </div>
            <div class="carousel-items col-6 col-md-3">
                <img src="../assets/photos/konfekcjowanie.jpg" alt="konfekcjonowanie">
                <h4>Konfekcjonowanie</h4>
            </div>
        </div>
    </div>
    <div class="container my-3">
        <div class="row text-justify mx-lg-5 align-items-center">
            <div class="d-flex flex-column mx-auto col-md-5 p-4 order-2 order-md-1">
                <img class="rounded" src="../assets/photos/pralnia-samochod.jpg" alt="pralnia">
            </div>
            <div class="d-flex flex-column  mx-auto col-md-7 p-2 order-1 order-md-2">
                <h3 class="">Dlaczego wybrać Pralnię Primo?</h3>
                <ul>
                    <li>24 lata doświadczenia</li>
                    <li>Zaufanie klientów z Łeby, Trójmiasta i okolic</li>
                    <li>Wysoki standard usług i terminowość</li>
                    <li>Profesjonalny personel zawsze do Twojej dyspozycji</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container my-3">
        <div class="row text-justify mx-lg-5 align-items-center">
            <div class="d-flex flex-column mx-auto col-md-7 p-4">
                <h3 class="">Nasza oferta:</h3>
                <ul>
                    <li>Pranie pościeli hotelowej, ręczników, narzut na łóżka, zasłon, firan</li>
                    <li>Pranie bielizny gastronomicznej: obrusy, serwety</li>
                    <li>Pranie dywanów i czyszczenie wykładzin</li>
                    <li>Indywidualne życzenia klienta: szlafroki, pokrowce na materace, zasłonki prysznicowe i inne</li>
                    <li>Indywidualnie dostosowane usługi poza pralnią, spełniające Twoje potrzeby</li>
                </ul>
            </div>
            <div class="d-flex flex-column mx-auto col-md-5 p-4">
                <img class="rounded" src="../assets/photos/dywany.jpg" alt="pranie dywany">
            </div>
        </div>
    </div>
</main>