<div #links id="links" class="row">
  <div class="col-sm-12 col-md-4">
  </div>
  <div id="map" class="col-md-12">
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe width="100%" height="100%"
          src="https://maps.google.com/maps?q=Pralnia,%20%C5%BBwirowa%201,%2084-360%20Now%C4%99cin+(Pralnia%20Primo)t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0"
          scrolling="no"></iframe>
      </div>
    </div>
  </div>
</div>
<footer class="d-flex flex-column align-items-center py-4 text-light text-center">

  <small>
    <div id="copyright">Wszystkie prawa zastrzeżone © Pralnia Primo 2024</div>
    <div id="small_menu" class="d-flex pt-3 text-center justify-content-between">
      <a (click)="app.scrollTop()" class="user-select-none text-light w-100" routerLink="/">Strona główna</a>
      <a (click)="app.scrollTop()" class="user-select-none text-light w-100" routerLink="/contact">Kontakt</a>
    </div>
  </small>
</footer>