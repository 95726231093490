import { Component } from '@angular/core'
import * as $ from "jquery"
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterOutlet, NavigationComponent, FooterComponent]
})

export class AppComponent {
  hero:string = ''
  heroVideo:string = ''

  scrollTop(){
    $("html, body").animate({ scrollTop: 0 });
  }
}


